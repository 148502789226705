nav ul {
	margin: 0;
	padding: 0;
	list-style: none;
    text-align: right;
}

nav ul > li {
	display: inline-block;
    margin-right: 0.3em;
}

nav ul > li > a,
nav ul > li > button {
	display: inline-block;
	padding: 0.4em 0.6em 0.4em 0.6em;
    border: 0;
	border-radius: 0.4em;
	color: #000;
	background-color: #fff;
	font-weight: bold;
	margin-bottom: 0.5em;
    text-decoration: none;
    cursor: pointer;
}

nav ul > li > a:hover,
nav ul > li > button:hover {
	color: #fff;
	background-color: #be0000;
	text-decoration: none;
}

nav ul li img {
    vertical-align: middle;
}
