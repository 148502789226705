
header {
	margin-bottom: 1em;
}

header h1 {
	padding: 0;
	margin: 0;
}

header h1 a {
	padding: 0.75em;
	display: inline-block;
	background-color: #be0000;
}

header h1 img {
	max-height: 85px;
}
