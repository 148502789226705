html, body {
	background: none;
	margin: 0;
	padding: 0;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
	font-size: 14px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
	background-image: url('https://s3-eu-west-1.amazonaws.com/badminton/uploads/photos/bg.jpg');
	background-size: cover;
	background-attachment: fixed;
	background-position: top center;
	background-repeat: no-repeat;
}

@media (max-height: 256px) {
	html {
		background-image: url('https://s3-eu-west-1.amazonaws.com/badminton/uploads/photos/bg_256.jpg');
	}
}
@media (max-height: 512px) {
	html {
		background-image: url('https://s3-eu-west-1.amazonaws.com/badminton/uploads/photos/bg_512.jpg');
	}
}
@media (max-height: 768px) {
	html {
		background-image: url('https://s3-eu-west-1.amazonaws.com/badminton/uploads/photos/bg_768.jpg');
	}
}
@media (max-height: 1024px) {
	html {
		background-image: url('https://s3-eu-west-1.amazonaws.com/badminton/uploads/photos/bg_1024.jpg');
	}
}

body {
	padding-top: 2em;
	padding-bottom: 2em;
}

@media (max-width: 768px) {
	body {
		padding-top: 1em;
	}
}

p {
	margin-block-start: 0.5em;
	margin-block-end: 0.5em;
}

/* a { color: #be0000; }
a:hover { color: #be0000; }
a:focus { color: #be0000; } */

h2 {
	display: inline-block;
	margin: 0.25em 0 0 0;
	padding: 0 0.5em;
	background-color: #be0000;
	color: #fff;
	font-weight: bold;
	font-size: 1.5em;
	line-height: 1.5em;
}

h2 a,
h2 a:hover {
	color: #fff;
}

h2.green { background-color: #19a34a; }
h2.blue { background-color: #0099ff; }

.icon {
	font-size: 24px;
	width: 1em;
	height: 1em;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

:root, [data-amplify-theme] {
	--amplify-components-button-primary-background-color: #be0000;
	--amplify-components-button-primary-hover-background-color: #a50000;
	--amplify-components-button-primary-border-color: var(--amplify-components-button-primary-background-color);
	--amplify-components-button-primary-hover-border-color: var(--amplify-components-button-primary-hover-background-color);

	--amplify-components-button-link-color: #be0000;
	--amplify-components-button-link-hover-color: var(--amplify-components-button-link-color);
	--amplify-components-button-link-hover-background-color: #F2CCCC;
	--amplify-components-button-link-focus-color: var(--amplify-components-button-link-color);
	--amplify-components-button-link-focus-background-color: var(--amplify-components-button-link-hover-background-color);
	--amplify-components-button-link-focus-border-color: var(--amplify-components-button-primary-background-color);
	--amplify-components-button-link-focus-box-shadow: none;
	--amplify-components-button-link-active-color: var(--amplify-components-button-link-color);
	--amplify-components-button-link-active-background-color: var(--amplify-components-button-link-hover-background-color);
	--amplify-components-button-link-active-border-color: var(--amplify-components-button-link-focus-border-color);

	--amplify-components-tabs-item-active-color: var(--amplify-components-button-primary-background-color);
	--amplify-components-tabs-item-active-border-color: var(--amplify-components-tabs-item-active-color);
	--amplify-components-tabs-item-hover-color: var(--amplify-components-tabs-item-active-color);
	--amplify-components-tabs-item-focus-color: var(--amplify-components-tabs-item-active-color);
}
